import * as firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'

// firebase init - add your own config here
const firebaseConfig = {
    apiKey: "AIzaSyAy2CLPe20P-7jxCb1hqYPHEbW0DhFoi10",
    authDomain: "employee-list-afa36.firebaseapp.com",
    databaseURL: "https://employee-list-afa36.firebaseio.com",
    projectId: "employee-list-afa36",
    storageBucket: "employee-list-afa36.appspot.com",
    messagingSenderId: "35733451805",
    appId: "1:35733451805:web:c92a3c700cf848ca4ec2f4"
}
firebase.initializeApp(firebaseConfig)

// utils
const db = firebase.firestore()
const FieldValue = firebase.firestore.FieldValue
const auth = firebase.auth()

// collection references
const usersCollection = db.collection('users')
const personsCollection = db.collection('persons')
const tagsCollection = db.collection('tags')

// export utils/refs
export {
    db,
    auth,
    FieldValue,
    usersCollection,
    personsCollection,
    tagsCollection
}