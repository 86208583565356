<template>
  <div id="app">
    <Navigation />
    <div class="content-section">
      <div class="container">
      <router-view/>
    </div>
    </div>
  </div>
</template>

<script>
import Navigation from '@/components/Navigation'

export default {
  components: {
    Navigation
  }
}
</script>

<style lang="scss">

</style>
