import Vue from 'vue'
import Vuex from 'vuex'
import * as fb from '../firebase'
import router from '../router/index'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    userProfile: {},
    tags: [],
    persons: []
  },
  mutations: {
    setUserProfile(state, val) {
      state.userProfile = val
    },
    setTags(state, val) {
      state.tags = val
    },
    setPersons(state, val) {
      state.persons = val
    }
  },
  actions: {
    async login({ dispatch }, form) {
      // sign user in
      const { user } = await fb.auth.signInWithEmailAndPassword(form.email, form.password)

      // fetch user profile and set in state
      dispatch('fetchUserProfile', user)
    },
    async fetchUserProfile({ commit }, user) {
      // fetch user profile
      const userProfile = await fb.usersCollection.doc(user.uid).get()

      // set user profile in state
      commit('setUserProfile', userProfile.data())

      // change route to home
      if (router.currentRoute.path === '/login') {
        router.push('/')
      }
    },
    async signup({ dispatch }, form) {
      // sign user up
      const { user } = await fb.auth.createUserWithEmailAndPassword(form.email, form.password)

      // create user profile object in usersCollection
      await fb.usersCollection.doc(user.uid).set({
        name: form.name,
      })

      // fetch user profile and set in state
      dispatch('fetchUserProfile', user)
    },
    async logout({ commit }) {
      await fb.auth.signOut()

      // clear userProfile and redirect to /login
      commit('setUserProfile', {})
      router.push('/login')
    },
    async newTag({ state, commit }, tag) {
      await fb.tagsCollection.add({
        content: tag.content,
        userId: fb.auth.currentUser.uid,
        userName: state.userProfile.name
      })
    },
    async updateTag({ state, commit }, tag) {
      await fb.tagsCollection.doc(tag.id).update({
        content: tag.content
      })
    },
    async deleteTag({ state, commit }, tag) {
      await fb.personsCollection
      .where("userId", "==", fb.auth.currentUser.uid)
      .where("tags", "array-contains", tag.id)
      .get()
      .then( response => {
        response.docs.forEach((doc) => {
          fb.personsCollection.doc(doc.id).update({
            tags: fb.FieldValue.arrayRemove(tag.id)
          })
        })
      })

      await fb.tagsCollection.doc(tag.id).delete()
    },
    async newPerson({ state, commit }, person) {
      await fb.personsCollection.add({
        name: person.name,
        firstname: person.firstname,
        email: person.email,
        mobilenr: person.mobilenr,
        homenr: person.homenr,
        tags: person.tags,
        userId: fb.auth.currentUser.uid,
        userName: state.userProfile.name
      })
    },
    async updatePerson({ state, commit }, person) {
        await fb.personsCollection.doc(person.id).update({
            name: person.name,
            firstname: person.firstname,
            email: person.email,
            mobilenr: person.mobilenr,
            homenr: person.homenr,
            tags: person.tags,
            userId: fb.auth.currentUser.uid,
            userName: state.userProfile.name
        })
    },
    async deletePerson({ state, commit }, person) {
      fb.personsCollection.doc(person.id).delete()
    }
  },
  modules: {
  }
})

export default store 

fb.auth.onAuthStateChanged(function (user) {
  if (user) {
    snapTags(fb.auth.currentUser.uid)
    snapPersons(fb.auth.currentUser.uid)
  } 
});

function snapTags(currentUser) {
  fb.tagsCollection.where("userId", "==", currentUser).onSnapshot(snapshot => {
    let tagsArray = []

    snapshot.forEach(doc => {
      let tag = doc.data()
      tag.id = doc.id
      tagsArray.push(tag)
    })

    store.commit('setTags', tagsArray)
  })
}

function snapPersons(currentUser) {
  fb.personsCollection.where("userId", "==", currentUser).onSnapshot(snapshot => {
    let personsArray = []

    snapshot.forEach(doc => {
      let person = doc.data()
      person.id = doc.id
      personsArray.push(person)
    })

    store.commit('setPersons', personsArray)
  })
}

