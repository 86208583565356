<template>
  <header id="navigation-bar">
      <section>
        <router-link to="/">
            <h3>Kontaktliste</h3>
        </router-link>
        <span>{{ showName }}</span>
        <ul v-if="showNav" class="hidden-sm hidden-xs">
          <li><router-link to="/">Home</router-link></li>
          <li><router-link to="/settings">Settings</router-link></li>
          <li><a @click="logout()">Logout</a></li>
        </ul>
      </section>
  </header>
</template>

<script>
import { mapState } from 'vuex'

export default {
    methods: {
        logout() {
            this.$store.dispatch('logout')
        }
    },
    computed: {
        ...mapState(['userProfile']),
        showNav() {
            return Object.keys(this.userProfile).length > 0 
        },
        showName() {
            return this.userProfile.name
        }
    }
}
</script>

<style>

</style>