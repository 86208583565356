<template>
  <div id="home">
    <header>
      <h1>Home</h1>
    </header>
    <button class="button" @click="toggleSettings()">Settings</button>
        <div class="settings" v-show="settings">
            <div id="entries" class="panel">
                <div class="new-entry">
                    <h2>Neuer Eintrag</h2>
                    <form @submit.prevent>
                        <div class="form-wrapper">
                            <div class="input-fields">
                                <h4>Daten</h4>
                                <div>
                                    <label for="name3" aria-hidden hidden>Name</label>
                                    <input v-model.trim="person.name" type="text" name="name3" id="name3" placeholder="Name" required aria-required>
                                </div>
                                <div>
                                    <label for="firstname" aria-hidden hidden>Vorname</label>
                                    <input v-model.trim="person.firstname" type="text" name="firstname" id="firstname" placeholder="Vorname" required aria-required>
                                </div>
                                <div>
                                    <label for="email3" aria-hidden hidden>Email</label>
                                    <input v-model.trim="person.email" type="email" name="email3" id="email3" placeholder="Email-Adresse">
                                </div>
                                <div>
                                    <label for="mobilenr" aria-hidden hidden>Mobil</label>
                                    <input v-model.trim="person.mobilenr" type="text" name="mobilenr" id="mobilenr" placeholder="Mobil Nr.">
                                </div>
                                <div>
                                    <label for="homenr" aria-hidden hidden>Festnetz</label>
                                    <input v-model.trim="person.homenr" type="text" name="homenr" id="homenr" placeholder="Festnetz">
                                </div>
                            </div>
                            
                            <div v-if="tags.length" class="tags-select">
                                <h4>Funktion(en)</h4>
                                <div>
                                    <ul>
                                        <li v-for="tag in tags" :key="tag.id" class="tag">
                                            <label>
                                                <input type="checkbox" name="tag" :value="tag.id" v-model.trim="person.tags">
                                                {{ tag.content }}
                                            </label>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <fa-icon :icon="['far', 'plus-square']" @click="newPerson()" v-if="!person.edit"  />
                        <fa-icon :icon="['far', 'save']" @click="updatePerson()" v-if="person.edit"  />
                        <fa-icon :icon="['far', 'trash-alt']" @click="deletePerson()" v-if="person.edit"  />
                        <fa-icon :icon="['far', 'window-close']" @click="endPersonEdit()" v-if="person.edit"  />
                    </form>
                </div>
            </div>
        <div id="tags" class="panel">
            <div class="new-tag">
                <h2>Funktionen</h2>
                <form @submit.prevent>
                    <div>
                        <label for="inputTag" aria-hidden hidden>Neue Funktion</label>
                        <input v-model.trim="tag.inputTag" type="text" name="inputTag" id="inputTag" placeholder="Neue Funktion" v-if="!tag.edit">
                        <input @keydown.esc="endTagEdit()" v-model.trim="tag.inputTag" type="text" name="inputTag" id="inputTag" placeholder="Bearbeite" v-if="tag.edit">
                        <fa-icon :icon="['far', 'plus-square']" @click="newTag()" v-if="!tag.edit"  />
                        <fa-icon :icon="['far', 'save']" @click="updateTag()" v-if="tag.edit"  />
                        <fa-icon :icon="['far', 'trash-alt']" @click="deleteTag()" v-if="tag.edit"  />
                        <fa-icon :icon="['far', 'window-close']" @click="endTagEdit()" v-if="tag.edit"  />
                    </div>
                </form>
            </div>
            <div>
                <ul v-if="tags.length">
                    <li v-for="tag in tags" :key="tag.id" class="tag" >
                        <fa-icon :icon="['far','edit']" v-show="settings" @click="editTag(tag.id, tag.content)"/> {{ tag.content }}
                    </li>
                </ul>
            </div>
        </div>
      </div>
    <main>
       <div id="entry-table" class="panel hidden-xs hidden-sm hidden-md" v-if="persons.length">
          <table id="table">
            <thead>
                <tr>
                    <th @click="sortTable(0)">Name</th>
                    <th @click="sortTable(1)">Vorname</th>
                    <th @click="sortTable(2)">Email</th>
                    <th @click="sortTable(3)">Mobil</th>
                    <th @click="sortTable(4)">Festnetz</th>
                    <th @click="sortTable(5)">Tags</th>
                    <th v-show="settings"></th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="person in persons" 
                :key="person.id"
                class="person">
                    <td>{{ person.name }}</td>
                    <td>{{ person.firstname }}</td>
                    <td><a :href="`mailto:${person.email}`">{{ person.email }}</a></td>
                    <td><a :href="`tel:${person.mobilenr}`">{{ person.mobilenr }}</a></td>
                    <td><a :href="`tel:${person.homenr}`">{{ person.homenr }}</a></td>
                    <td>
                        <ul v-if="person.tags.length" class="tags">
                            <li v-for="(persontag, index) in person.tags" :key="index">
                                <span v-if="showTagName(persontag)!=null">{{ showTagName(persontag) }}</span>
                            </li>
                        </ul>
                        <span v-else>--------</span>
                    </td>
                    <td v-show="settings"><fa-icon :icon="['far','edit']" @click="editPerson(person.id, person.name, person.firstname, person.email, person.mobilenr, person.homenr, person.tags)" /></td>
                </tr>
            </tbody>
          </table>
        </div>
        <div id="entry-mobile" class="panel hidden-lg hidden-xl" v-if="persons.length">
            <div v-for="person in persons" :key="person.id">
                <h5><fa-icon :icon="['far','edit']" v-show="settings" @click="editPerson(person.id, person.name, person.firstname, person.email, person.mobilenr, person.homenr, person.tags)" />
                    {{ person.firstname }} {{ person.name }}</h5>
                <div v-if="person.email">Email: <a :href="`mailto:${person.email}`">{{ person.email }}</a></div>
                <div v-if="person.mobilenr">Mobil: <a :href="`tel:${person.mobilenr}`">{{ person.mobilenr }}</a></div>
                <div v-if="person.homenr">Home: <a :href="`tel:${person.home}`">{{ person.homenr }}</a></div>
                <div v-if="person.tags.length" class="tags">
                    <span v-for="(persontag, index) in person.tags" :key="index">
                        <span v-if="showTagName(persontag)!=null">{{ showTagName(persontag) }} </span>
                    </span>
                </div>
                <div v-else>--------</div>
                <!-- TODO: Styling via CSS instead of HR-Element -->
                <br><hr><br>
            </div>
        </div>
    </main>
    
  </div>
</template>

<script>
// @ is an alias to /src
import { mapState } from 'vuex'

export default {
  name: 'Home',
  components: {
  },
  data() {
    return {
        settings: false,
        person: {
            name: '',
            firstname: '',
            email: '',
            mobilenr: '',
            homenr: '',
            tags: [],
            edit: false,
            updateId: ''
        },
        tag: {
            inputTag: '',
            updateId: '',
            edit: false 
        }
    }
  },
  computed: {
      ...mapState(['persons']),
      ...mapState(['userProfile']),
      ...mapState(['tags']),
  },

  methods: {
    toggleSettings() {
        if ( this.settings ) { 
            this.endTagEdit()
            this.endPersonEdit()
            }
        this.settings = !this.settings
    },
    newPerson() {
        if (this.person.name.length > 0 && this.person.firstname.length > 0) {
            this.$store.dispatch('newPerson', { 
                name: this.person.name,
                firstname: this.person.firstname,
                email: this.person.email,
                mobilenr: this.person.mobilenr,
                homenr: this.person.homenr,
                tags: this.person.tags
            })

            this.endPersonEdit()
        }
    },
    editPerson(id, name, firstname, email, mobilenr, homenr, tags) {
        this.person.name = name
        this.person.firstname = firstname
        this.person.email = email
        this.person.mobilenr = mobilenr
        this.person.homenr = homenr
        this.person.tags = tags
        this.person.edit = true
        this.person.updateId = id
        document.getElementById("name3").focus()

    },
    updatePerson() {
        if (this.person.name.length > 0 && this.person.firstname.length > 0) {
            this.$store.dispatch('updatePerson', { 
                id: this.person.updateId,
                name: this.person.name,
                firstname: this.person.firstname,
                email: this.person.email,
                mobilenr: this.person.mobilenr,
                homenr: this.person.homenr,
                tags: this.person.tags
            })
        }
        this.endPersonEdit()
    },
    deletePerson() {
        if (confirm("Bist du dir sicher?")) {
            this.$store.dispatch('deletePerson', { id : this.person.updateId })
        } 
        this.endPersonEdit()
    },
    showTagName(id) {
        for (const tag of this.tags) {
            if ( tag.id == id ) {
                return tag.content
            }
        }
    },
    newTag() {
        let tagLowerCase = this.tag.inputTag.toLowerCase();
        if (tagLowerCase.length > 0) {
            this.$store.dispatch('newTag', { content: tagLowerCase })
        } else { alert("Input too short") }
        this.endTagEdit()
    },
    editTag(id, name) {
        this.tag.edit = true
        this.tag.inputTag = name
        this.tag.updateId = id
        document.getElementById("inputTag").focus()
    },
    updateTag() {
        let tagLowerCase = this.tag.inputTag.toLowerCase();
        if (tagLowerCase.length > 0) {
            this.$store.dispatch('updateTag', { id: this.tag.updateId, content: tagLowerCase})
        } else { alert("Input too short") }
        this.endTagEdit()
    },
    deleteTag() {
        if (confirm("Bist du dir sicher?")) {
            this.$store.dispatch('deleteTag', { id: this.tag.updateId }) 
        } 
        this.endTagEdit()
    },
    endTagEdit() {
        this.tag.inputTag = ''
        this.tag.updateId = ''
        this.tag.edit = false
    },
    endPersonEdit() {
        this.person.name = ''
        this.person.firstname = ''
        this.person.email = ''
        this.person.mobilenr = ''
        this.person.homenr = ''
        this.person.tags = []
        this.person.edit = false
        this.person.updateId = ''
    },
    sortTable(n) {
      var table, rows, switching, i, x, y, shouldSwitch, dir, switchcount = 0;
      table = document.getElementById("table");
      switching = true;

      dir = "asc";
      
      while (switching) {
          
        switching = false;
        rows = table.rows;
        
        for (i = 1; i < (rows.length - 1); i++) {
            shouldSwitch = false;
            
            x = rows[i].getElementsByTagName("TD")[n];
            y = rows[i + 1].getElementsByTagName("TD")[n];
        
            if (dir == "asc") {
                if (x.innerHTML.toLowerCase() > y.innerHTML.toLowerCase()) {
                // If so, mark as a switch and break the loop:
                shouldSwitch = true;
                break;
                }
            } else if (dir == "desc") {
                if (x.innerHTML.toLowerCase() < y.innerHTML.toLowerCase()) {
                // If so, mark as a switch and break the loop:
                shouldSwitch = true;
                break;
                }
            }
        }
        if (shouldSwitch) {
            rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
            switching = true;
            
            switchcount ++;
        } else {
            if (switchcount == 0 && dir == "asc") {
                dir = "desc";
                switching = true;
            }
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>